import axios from 'axios'
import router from "../router/index"
// 导入 NProgress 包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


export function request(config) {
    // 创建axios实例
    const instance = axios.create({
            baseURL: "http://139.9.0.126:8088/", // 线上接口
            // baseURL: "http://192.168.124.67:8088", // 本地接口
            // baseURL: "https://www.yxrzypt.com/", // 线上接口
            // 
            // baseURL: "http://192.168.124.36:97/", // 线下接口
            timeout: 30000,
        })
        // 请求拦截
    instance.interceptors.request.use(config => {
            NProgress.start()
            if (localStorage.TOKEN) { //判断TOKEN是否存在
                config.headers.Authorization = localStorage.TOKEN; //将TOKEN设置成请求头
            }
            return config
        }, err => {
            return Promise.reject(error)
        })
        // 响应拦截
    instance.interceptors.response.use(res => {
            NProgress.done()
                // if (res.data.code === 403) {
                //     router.replace({

            //         path: '/Login' // 到登录页重新获取token

            //     })
            // }
            return res
        },
        error => {
            // console.log(error.response)
            if (error.response.status === 403) {
                router.replace({

                    path: '/Login' // 到登录页重新获取TOKEN

                })
                alert('请重新登录')
            }
            return Promise.reject(error.response)
        })
    return instance(config)

}